/**
 * @generated SignedSource<<76cce987c39b311453f7905097b3b2da>>
 * @relayHash df4e64d0e217e2941023ecd5a705755d
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/505.0.0-2025-04-08T14:45:35.263Z/profileAwardsMutation

import { ConcreteRequest } from 'relay-runtime';
export type AwardStatusType = "ACTIVE" | "DELETED" | "PENDING" | "%future added value";
export type FirmDesignProfileAwardsMutationInput = {
  awardIds: ReadonlyArray<string | null>;
  clientMutationId?: string | null;
  serviceId: string;
};
export type profileAwardsMutation$variables = {
  input: FirmDesignProfileAwardsMutationInput;
};
export type profileAwardsMutation$data = {
  readonly firmDesignProfileAwardsMutation: {
    readonly tradeFirm: {
      readonly publicProfile: {
        readonly awards: ReadonlyArray<{
          readonly name: string | null;
          readonly serviceId: string | null;
          readonly status: AwardStatusType | null;
        } | null> | null;
      } | null;
    } | null;
  } | null;
};
export type profileAwardsMutation = {
  response: profileAwardsMutation$data;
  variables: profileAwardsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "serviceId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "profileAwardsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileAwardsMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileAwardsMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeFirmPublicProfileType",
                "kind": "LinkedField",
                "name": "publicProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AwardType",
                    "kind": "LinkedField",
                    "name": "awards",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "profileAwardsMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FirmDesignProfileAwardsMutationPayload",
        "kind": "LinkedField",
        "name": "firmDesignProfileAwardsMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "TradeFirmPublicProfileType",
                "kind": "LinkedField",
                "name": "publicProfile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AwardType",
                    "kind": "LinkedField",
                    "name": "awards",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      (v3/*: any*/),
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/505.0.0-2025-04-08T14:45:35.263Z/profileAwardsMutation",
    "metadata": {},
    "name": "profileAwardsMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "7106ba400084c1aabca4f39947641d8d";

export default node;
