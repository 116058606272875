/**
 * @generated SignedSource<<085688850b5175c149773e2fba63517e>>
 * @relayHash 6499330596ed04d8106d21e2b1bdcf5a
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/505.0.0-2025-04-08T14:45:35.263Z/FolderWrapperRefetchQuery

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FolderWrapperRefetchQuery$variables = {
  selectedItemIds?: ReadonlyArray<string | null> | null;
  shouldFillInFolder: boolean;
  userIds: ReadonlyArray<string | null>;
};
export type FolderWrapperRefetchQuery$data = {
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"FolderWrapper_viewer">;
  };
};
export type FolderWrapperRefetchQuery = {
  response: FolderWrapperRefetchQuery$data;
  variables: FolderWrapperRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "selectedItemIds"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "shouldFillInFolder"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userIds"
  }
],
v1 = {
  "kind": "Variable",
  "name": "userIds",
  "variableName": "userIds"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FolderWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "selectedItemIds",
                "variableName": "selectedItemIds"
              },
              {
                "kind": "Variable",
                "name": "shouldFillInFolder",
                "variableName": "shouldFillInFolder"
              },
              (v1/*: any*/)
            ],
            "kind": "FragmentSpread",
            "name": "FolderWrapper_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "RootQueryType",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FolderWrapperRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "condition": "shouldFillInFolder",
            "kind": "Condition",
            "passingValue": true,
            "selections": [
              {
                "alias": "folderItemMatches",
                "args": [
                  {
                    "kind": "Variable",
                    "name": "itemIds",
                    "variableName": "selectedItemIds"
                  },
                  {
                    "kind": "Literal",
                    "name": "portfolioTypes",
                    "value": [
                      "FOLDER"
                    ]
                  },
                  (v1/*: any*/)
                ],
                "concreteType": "PortfolioItemV2",
                "kind": "LinkedField",
                "name": "portfolioItemMatches",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Item",
                    "kind": "LinkedField",
                    "name": "item",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "serviceId",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ]
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/505.0.0-2025-04-08T14:45:35.263Z/FolderWrapperRefetchQuery",
    "metadata": {},
    "name": "FolderWrapperRefetchQuery",
    "operationKind": "query",
    "text": null
  }
};
})();

(node as any).hash = "3f27df42d8169a47c88db77d04e9c05e";

export default node;
