/**
 * @generated SignedSource<<3cb187cd2b055d92076d0f5fd91cfaff>>
 * @relayHash 25cd689b908322d734a742eb837a2003
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID app-buyer-trade/505.0.0-2025-04-08T14:45:35.263Z/profileEmailMutation

import { ConcreteRequest } from 'relay-runtime';
export type DesignProfileBasicMutationInput = {
  about?: string | null;
  clientMutationId?: string | null;
  email?: string | null;
  serviceId: string;
};
export type profileEmailMutation$variables = {
  input: DesignProfileBasicMutationInput;
};
export type profileEmailMutation$data = {
  readonly designProfileBasicMutation: {
    readonly tradeFirm: {
      readonly publicProfile: {
        readonly email: string | null;
      } | null;
    } | null;
  } | null;
};
export type profileEmailMutation = {
  response: profileEmailMutation$data;
  variables: profileEmailMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "TradeFirmPublicProfileType",
  "kind": "LinkedField",
  "name": "publicProfile",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "profileEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DesignProfileBasicMutationPayload",
        "kind": "LinkedField",
        "name": "designProfileBasicMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutations",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "profileEmailMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "DesignProfileBasicMutationPayload",
        "kind": "LinkedField",
        "name": "designProfileBasicMutation",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "TradeFirmType",
            "kind": "LinkedField",
            "name": "tradeFirm",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "id": "app-buyer-trade/505.0.0-2025-04-08T14:45:35.263Z/profileEmailMutation",
    "metadata": {},
    "name": "profileEmailMutation",
    "operationKind": "mutation",
    "text": null
  }
};
})();

(node as any).hash = "fb2ee3c3986c0ca07ce1e94f37912308";

export default node;
